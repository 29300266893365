import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/monkey/Projects/bkc-sajt/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`O Bosanskom Kulturnom Centru Gračanica`}</p>
    </PageDescription>
    <AnchorLinks small mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">NARODNI UNIVERZITET</AnchorLink>
  <AnchorLink mdxType="AnchorLink">BOSANSKI KULTURNI CENTAR</AnchorLink>
    </AnchorLinks>
    <h2>{`NARODNI UNIVERZITET`}</h2>
    <Row mdxType="Row">
      <Column colMd="10" colLg="12" mdxType="Column">
        <p>{`Od 1959. godine, u Gračanici postoji i djeluje Narodni univerzitet kao profesionalna društvena institucija za obrazovanje odraslih, kulturu i informisanje. Od tada vrlo aktivno djeluje u oblasti obrazovanja odraslih: obrazovanje za najrazličitija zanatska zanimanja, obrazovanja zemljoradnika, zdravstveno prosjećivanje i obuku domaćica pa do osnovne škole za obrazovanje odraslih, srednjih i viših škola raznih profila.`}</p>
      </Column>
      <Column colMd="10" colLg="12" mdxType="Column">
        <p>{`Narodni univerzitet je suvremeno i kvalitetno objedinjavao opće obrazovnu i opće kulturnu aktivnost. U njegovom sastavu bile su sljedeće institucije: Centar za obrazovanje odraslih i selo, Biblioteka dr.Mustafa Kamarić, Radio Gračanica, Kino i Centar za kulturu,kasnije Dom kulture. Do preseljenja u novi Dom kulture 1978. i 1979. godine, Dom kulture koristio je prostor zgrade medrese, dio nekadašnjeg Partizana i staro kino.`}</p>
      </Column>
    </Row>
    <PageDescription mdxType="PageDescription">
      <p>{`Pretrpivši mnoge organizacione promjene 1992. godine transformirao se u Bosanski kulturni cventar u sljedećim oblicima rada: Dom kulture - kulturna djelatnost, biblioteka, radio stanica i kino.`}</p>
    </PageDescription>
    <h2>{`BOSANSKI KULTURNI CENTAR`}</h2>
    <PageDescription mdxType="PageDescription">
      <p>{`Kako bi se unaprijedio kulturni život na općini, 1976.godine općinsko rukovodstvo na čelu sa ondašnjim predsjednikom općine, Ćamilom Naimkadićem, pokreće obimnu aktivnost za izgradnju savremenog kulturnog centra – Doma kulture u Gračanici: uz izuzetnu podršku građana – uveden je mjesni samodoprinos, tokom 1978. i 1979. godine izgrađena je prva faza i druga faza modernog zdanja ukupne površine 2600 kvadratnih metara. U njoj je veća pozorišna i kino dvorana, biblioteka, Radio Gračanica, Kuglana, Disco club, Kafe, prostrani – višenamjenski hol za različite izložbene postavke, Mala sala za predavanja i predstavljanja, te umjetnički atelje i prostorije administracije i uprave.`}</p>
    </PageDescription>
    <Row mdxType="Row">
      <Column colMd="10" colLg="12" mdxType="Column">
        <p>{`Nekadašnji Narodni univerzitet, transformira se u Bosanski kulturni centar, djeluje kao javna ustanova u oblasti kulture i informiranja. Pod krovom Bosanskog kulturnog cerntra Gračanica smješteni su i djeluju : Radio Gračanica, Biblioteka dr. Mustafa Kamarić, nadaleko čuveno gračaničko Kino, Galerija i Zavičajno-muzejska zbirka.`}</p>
      </Column>
      <Column colMd="10" colLg="12" mdxType="Column">
        <h4>{`Programske dominante Bosanskog Kulturnog centra su:`}</h4>
        <ul>
          <li parentName="ul">{`Festival dječijeg dramskog stvaralaštva, koji se organizira svake godine krajem travnja/aprila. Do sada je održano devet festivala uz učešće djece iz cijele Bosne i Hercegovine.`}</li>
          <li parentName="ul">{`Ljetne večeri pod lipama Gračanice – općinska ,tradicionalna kulturna manifestacija kolažnog tipa u srpnju/ julu i kolovozu/avgustu.`}</li>
          <li parentName="ul">{`Sarajevo Film Festival – repriza filmova sa ovog festivala održava se svake godine u kinu Gračanica. Repriza filmova sa ovog festivala održava se u Gračanici od 1997. godine . Do sada je u Gračanici održano 10 repriza filmova sa ovog Festivala.`}</li>
          <li parentName="ul">{`Dani knjige – tokom listopada/oktobra u Mjesecu knjige.`}</li>
          <li parentName="ul">{`Dani Bosanskog kulturnog centra Gračanica – kulturno-zabavna i revijalna manifestacija amatera, samostalnih stvaralaca, profesionalnih grupa u raznim oblastima umjetnosti.`}</li>
          <li parentName="ul">{`Stalna postavka Zavičajne zbirke: arheološki nalazi iz neolita, eneolita, brončanog doba i srednjeg vijeka, historijska zbirka iz Osmanskog perioda, numizmatička zbirka, itd.`}</li>
        </ul>
      </Column>
    </Row>
    <p><strong parentName="p">{`Tokom godine, Bosanski kulturni centar Gračanica organizira promocije knjiga, pozorišne predstave, tribine, izložbe slika, modne revije, književne susrete, poetske večeri, te različite gostojuće kulturne programe i sadržaje.`}</strong></p>
    <Row className="resource-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="87.6 & 95MHz" title="Radio Gračanica" aspectRatio="2:1" actionIcon="arrowRight" href="https://radiogracanica.ba/" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "20px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABX0lEQVQ4y9WUvSvFURjHv5GrvOVlMVAGxaiUUgYik8VsYfMHiFXEIKVMrtHbcF0uKYsYTBJ/AStKWUwi8Xnq/G4czpWDwalPv3Oe5/l9fuflnitFtIxUvC7VTEpF+o2GsCUrvexIjf9cuC3VbUq91t+XSrekvhWpPFqIrJ/CZ+sja7CXoPXbQkRjFDw6QQxPOObzQgKnsGxLjAHZLFzlhQTPYNzNdgAmElhe7VeHRN0QE7oJCadJnifkpCaL86xG3u7jDjIsDDXyg5/tH7KRgkK+2mljCq4pXGScsnhaKrFZ+lg+KFyTqkjcQRZG4Zb4lPtQh4u/g3xPUEii25ZhYnf6c3DkhG3ULPnYioJCkvUkHujP0O+if0HxgtXYjSHW7LMrVRbcQ57DJO/dph/Y39SPDsUtL5WIkrYnldlPyIfaig9CBsdwAukYEB7yvHx72e0EV5llJgbe3bArqL9or/1VEp7YS0AVAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Radio",
              "title": "Radio",
              "src": "/static/e2956832964b2700c8f1993c66b28b1e/3c1b5/radio.png",
              "srcSet": ["/static/e2956832964b2700c8f1993c66b28b1e/3c1b5/radio.png 20w"],
              "sizes": "(max-width: 20px) 100vw, 20px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Knjige od papira" title="Biblioteka" actionIcon="arrowRight" aspectRatio="2:1" color="dark" href="/biblioteka" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "20px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABEElEQVQ4y2NgGAjQwMDAtJaBIQSI03DhVQwM9kQbuIaBIRKIfwHxXRz4PhD/Xc/AoEyUgUAXFAM1XMclv4SBgQ8o/381A4PtEDFwPgMDByhQgRpdcOAZJBkIZKwGCRDAxBsIUgzyFtW8TIqBQHoaUOM6GAbyCyk1sB2I5yDhVJwGAiOGDUhLImOoGNxAIF8UWR6YmAVwGggUcEaPjHUMDE5oLryHLA+UW47TwI0MDLxA2ggZA13Eg2agNrI80IUKOA0EYiUguwUZg8TQDCxCUxOG00AgbQLEx5Ex0IXGaAauQVPTSJd0uA2IvwE1vsOGQXJA/AeP/HtQ8QZky4MNBEU90PQgIA4lFwMN1KNJ6Q4APjMRUCetfcIAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Knjige",
              "title": "Knjige",
              "src": "/static/26f2f901a763864f222144d6dec41fd0/3c1b5/knjige.png",
              "srcSet": ["/static/26f2f901a763864f222144d6dec41fd0/3c1b5/knjige.png 20w"],
              "sizes": "(max-width: 20px) 100vw, 20px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Preuzmite brošuru u .pdf formatu" title="Zavičajna muzejska zbirka" aspectRatio="2:1" color="dark" actionIcon="download" href="/zmz" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "20px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB1klEQVQ4y9WUTSinURTGj6kpaTJmwcaCjYWywUgsZmGasrKylJKdhRWW2MhOFixIMcWMjz8WVj5SsrCgFDa+y4KmJpHPhL/fU+et1+f8MSVvPd1zn3vuc+8957zH7N19UbO4IbP2iNlkDMj+pyBiNTiegk7QAXrANYiCReeEObDea5b4qNigWQFOF8NmlQGH3QD3F3SBXXw+ix81S2K+CQYeFOsz+8LiNugPOOwMcMatK8bMEiSgcITWv4JzuKp7cWNhFKwFT3BuCszIFjdiVsz8CoHCkGi1RHlJzt24KUbToRhFxLE2EeKEA8XuDnciLgiHTql3wWVOGhQ86EpGJOCcX2XcD+YcOKJbgy1ekBJ+co8HP8MTlIp9iVNRODxw86AlNG8Dh/hl3Ypjh9lHr60NxJLdWfPuwIdNmf6SbK+AOlUFt/z+YKaVEByWcJxFJR7HcubHHPDJD2gGKy5W6gkqf7Kwqa90HPdUXxJiPAJljWYfGHdALWJ5noj6mH4/hHJ1MzY2gZ/Y4zz3h27E/BvjH/ArKKeYPjaWKCmMvzUqntgLyrLqUzF/dqPwor3yRETdXtFv9+Lug0BrSHCPW6a9qp0pGV686kD5/6VHqjEoGW/SoG8Ap/LrZ+AQdOAAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Zbirka",
              "title": "Zbirka",
              "src": "/static/b89b24f95ed514e6b0a2628cefa977e1/3c1b5/zbirka.png",
              "srcSet": ["/static/b89b24f95ed514e6b0a2628cefa977e1/3c1b5/zbirka.png 20w"],
              "sizes": "(max-width: 20px) 100vw, 20px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Na platnu" title="Kino" aspectRatio="2:1" href="/kino" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "20px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA40lEQVQ4y2NgGAVUAWsZGGRWMzBsB9JniMFAtbtWMTAo4jRwDQNDIRA/ACosJwYD1d4G0lVIDjIF8qORXVgGVHSUWB8B1e4GGtAAdCUzkF0NxL+A+CJFBgLxXCA+CMRvgHg9NQz8D3TlXqArpYF0FqUGloLCvYGBgQnEp9hAdECxgUD1S4DqNwC9K0oVA6Fh+AOInwOxO7UMbAHqawXSv4H4CsUGgtIhiA30tj2Q/wiIzyIHajooPQENXkUMBqp/AaQLYPqBhvKvY2DQZEAS4AS5Eog7iMFAyysWMTBwj5aCYAAAxlWdv2NtBLkAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Kino",
              "title": "Kino",
              "src": "/static/00d23ea290512fbbbd3b67282b1d6c06/3c1b5/kino.png",
              "srcSet": ["/static/00d23ea290512fbbbd3b67282b1d6c06/3c1b5/kino.png 20w"],
              "sizes": "(max-width: 20px) 100vw, 20px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      